
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { apiRequest } from "@/helpers/api-call";
import router from "@/router/index.js";
import Vue from "vue";

/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    //Multiselect,
    //ckeditor: CKEditor.component,
  },
  data() {
    return {

      fields: [
        { key: 'reseau', label: 'Réseau' },
        { key: 'indicatif', label: 'Indicatif' },
        { key: 'nbre_chaine', sortable: true, label: 'Nombre de chaînes' },
        { key: 'action', label: 'Action', sortable: false }
      ],

      title: "GESTION DES Réseaux GSM",
      items: [
        {
          text: "Dashboard",
        },
        {
          text: "Réseaux GSM",
          active: true,
        },
      ],
      // Les données que j'insère
      isEditing: false,
      reseauMoov: [],
      reseauMTN: [],
      reseaux: [],
      reseauIndicatif229: [],
      editGsmModal: false,
      gsmModal: false,
      addGsmModal: false,
      // fin des données que j'insère
      totalRows: 1,
      deleteId: 0,
      deleteModal: false,
      changeCurrent: 1,
      changePerPage: 100,
      currentPage: 1,
      currentPageD: 1,
      perPage: 100,
      perPageD: 100,
      pageOptions: [100, 250, 500],
      pageOptionsD: [100, 250, 500],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      paginatedDate: [],
      carData: [],
      goClassData:[],
      VTCM:[],
      pourcentageData: [],
      covData: [],
      gsmFormData: {
        id: null,               // Identifiant unique du réseau
        reseau: "",             // Nom du réseau (par exemple, 'Moov Africa Bénin', 'MTN Bénin')
        indicatif: "",          // Indicatif du réseau (par exemple, '+229')
        nbre_chaine: null,      // Nombre de chaînes associées au réseau
        prefixe: [],            // Liste des préfixes associés au réseau (par exemple, ['90', '91'])
      },

    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.dataUser.length;
    },
    rowsinactif() {
      return this.dataUserD.length;
    },
  },
  async mounted() {

    this.getListeReseaux();
    
  },
  methods: {


    async getListeReseaux() {
      //  messageContent
      const response = await apiRequest('GET', 'list/reseau', false)

      if (response && response.status == 200) {
        console.log("Liste des réseaux", response.data);
        
        // Trier les données par id décroissant
        //const sortedData = response.data.all.sort((a, b) => b.id - a.id);
        this.reseaux = response.data.all.sort((a, b) => b.id - a.id);
        
        // Filtrer les réseaux ayant un certain nombre de chaînes (par exemple)
        // this.reseauMoov = sortedData.filter(el => el.reseau == 'Moov Africa Bénin');
        // console.log("Moov Africa Bénin réseau: ", this.reseauMoov);

        // this.reseauMTN = sortedData.filter(el => el.reseau == 'MTN Bénin');
        console.log("MTN Bénin réseau: ", this.reseauMTN);
        
    }

    },

    async editGsmModalOpener(el) {
    console.log("value", el);

    // Ouvrir le modal d'édition
    this.editGsmModal = true;

    // Remplir les données du formulaire avec les données du réseau sélectionné
    this.gsmFormData = {
      id: el.id,                // ID du réseau
      reseau: el.reseau,        // Nom du réseau (Moov Africa Bénin, MTN Bénin, etc.)
      indicatif: el.indicatif,  // Indicatif du réseau
      nbre_chaine: el.nbre_chaine, // Nombre de chaînes associées à ce réseau
      prefixe: el.prefixe,       // Préfixes des numéros pour ce réseau (si nécessaire)
    };
    },

    async openAddGsmModal() {
      console.log("Ouverture du modal d'ajout");
      this.isEditing = false;
      this.addGsmModal = true;
      this.gsmFormData = {
        reseau: '',
        indicatif: '',
        nbre_chaine: '',
        prefixe: '',
        mode_feda: ''
      };
    
    },

    handleDelete(id) {

      this.deleteId = id;
      this.deleteModal = true

    },
    async deleteP() {
      const response = await apiRequest('DELETE', 'destroy-taxi-tarif-maj', { id: this.deleteId })
      console.log("response", response)
      if (response.status == 200) {
        this.deleteModal = false
        this.getPercentage()
        this.$toast.success("Opération réusssie")

      }

    },

    async updateReseauMethod() {
      console.log("gsmFormData", this.gsmFormData);
      this.editGsmModal = false;

      const response = await apiRequest('PUT', `update/reseau/`, {
        id: this.gsmFormData.id, // Ajout de l'ID dans le corps de la requête
        reseau: this.gsmFormData.reseau,
        indicatif: this.gsmFormData.indicatif,
        nbre_chaine: this.gsmFormData.nbre_chaine,
        prefixe: this.gsmFormData.prefixe, // Envoi direct s'il est déjà un tableau
        // prefixe: JSON.stringify(this.gsmFormData.prefixe), // Convertir en JSON si besoin
        // Ajouter d'autres champs si nécessaires
      });

      if (response && response.status === 200) {
        console.log("Données mises à jour", this.gsmFormData);
        
        this.editGsmModal = false;

        this.$toast.success("Modifications apportées avec succès !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
        
        // Recharger les données des réseaux
        await this.getListeReseaux();

      } else {
        this.$toast.error("Échec de la modification !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
      }
    },

    async addReseauMethod() {
    console.log("Données du formulaire", this.gsmFormData);

    // Fermer le modal d'ajout (si vous en utilisez un)
    this.addGsmModal = false;

    const response = await apiRequest('POST', `nouveau-reseau`, {
      reseau: this.gsmFormData.reseau,
      indicatif: this.gsmFormData.indicatif,
      nbre_chaine: this.gsmFormData.nbre_chaine,
      prefixe: this.gsmFormData.prefixe.split(',').map(p => p.trim()), // Convertir en tableau
      mode_feda: this.gsmFormData.mode_feda,
    });
    console.log("just av",this.gsmFormData);

    if (response && response.status === 200) {
      console.log("Nouveau réseau ajouté", this.gsmFormData);
      this.addGsmModal = false;
      this.$toast.success("Réseau ajouté avec succès !!!", {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false
      });

      // Recharger les données des réseaux
      await this.getListeReseaux();
      
    } else {
      console.log("just av",this.gsmFormData);

      this.$toast.error("Échec de l'ajout du réseau !!!", {
        
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false
      });
    }
  },




    deleteUser(row) {
      this.userToUse = row.id;
      // this.showDelete = true;
    },
    setDepartContry: function (place) {
      if (place) {
        this.infoUser.ville = place.formatted_address;
      }
    },

    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.badge === true) return "table-warning";
    },
    activeUserPop(row) {
      this.userToUse = row.id;
      this.deleteModal = true;
    },
    position() {
      Vue.swal({
        position: "center-end",
        icon: "success",
        title: "Mise à jour effectuée",
        showConfirmButton: false,
        timer: 3000,
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    goToDetail(row) {
      router.push(`/user/user_info/${row.id}`);
    },
    openNewTab(row) {
      window.open(
        `https://adminrmobility.raynis.co/user/user_info/${row.id}`,
        "_blank"
      );
    },


  },

  watch: {

  },
};


</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row rowBase">
      <div class="col-lg-12 ">
        <div class="card p-4 ">
          <b-tabs pills card content-class="mt-3" justified>
            <!-- Première tab pour le réseau Moov Africa Bénin -->
            <!-- <b-tab active class="" title="Moov Africa Bénin">
              <b-table striped responsive :items="reseauMoov" :fields="fields">
                <template #cell(action)="row">
                  <div>
                    <a @click.prevent="editGsmModalOpener(row.item)">
                      <i class="fas fa-pen md"></i>
                    </a>
                  </div>
                </template>
              </b-table>
            </b-tab> -->
            <b-tab title="Tous les Réseaux">
            <b-table striped responsive :items="reseaux" :fields="fields">
              <template #cell(action)="row">
                <div>
                  <a @click.prevent="editGsmModalOpener(row.item)">
                    <i class="fas fa-pen md"></i>
                  </a>
                </div>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>

             <!-- Deuxième tab pour le réseau MTN Bénin -->
            <!-- <b-tab title="MTN Bénin">
              <b-table striped responsive :items="reseauMTN" :fields="fields">
                <template #cell(action)="row">
                  <div>
                    <a @click.prevent="editGsmModalOpener(row.item)">
                      <i class="fas fa-pen md"></i>
                    </a>
                  </div>
                </template>
              </b-table>
            </b-tab> -->
            
        

        </div>
      </div>
    </div>
    <b-modal id="modal-backdrop" centered v-model="deleteModal"
      title="Voulez-vous vraiment supprimer cette majoration ?" title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="deleteModal = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="deleteP()">Oui</b-button>
      </div>
    </b-modal>

    <b-button variant="primary" @click="openAddGsmModal">
    Ajouter
  </b-button>



    <b-modal id="modal-backdrop" centered title="Voulez-vous vraiment conserver les modifications apportées ?"
      title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4">Oui</b-button>
      </div>
    </b-modal>
    <!-- Change commission-->
    <b-modal v-model="editGsmModal" size="lg" title="Modifier les informations du réseau" centered>
      <form>

        <div class="row form-group">
          <div class="col-md-6 form-group">
            <label>Nom du réseau</label>
            <input type="text" class="form-control" placeholder="Nom du réseau"
              v-model="gsmFormData.reseau" />
          </div>
          <div class="col-md-6 form-group">
            <label>Indicatif</label>
            <input type="text" class="form-control" placeholder="Indicatif"
              v-model="gsmFormData.indicatif" />
          </div>
        </div>

        <div class="row form-group">
          <div class="col-md-6 form-group">
            <label>Nombre de chaînes</label>
            <input type="number" class="form-control" placeholder="Nombre de chaînes"
              v-model="gsmFormData.nbre_chaine" />
          </div>
          <div class="col-md-6 form-group">
            <label>Préfixes</label>
            <input type="text" class="form-control" placeholder="Préfixes (séparés par des virgules)"
              v-model="gsmFormData.prefixe" />
          </div>
        </div>

      </form>

      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="editGsmModal = false">Fermer</b-button>
        <b-button variant="primary" @click="updateReseauMethod">
          Modifier
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="addGsmModal" size="lg" title="Ajouter un nouveau réseau" centered>
      <form>

      <div class="row form-group">
        <div class="col-md-6 form-group">
          <label>Nom du réseau</label>
          <input type="text" class="form-control" placeholder="Nom du réseau"
            v-model="gsmFormData.reseau" />
        </div>
        <div class="col-md-6 form-group">
          <label>Indicatif</label>
          <input type="text" class="form-control" placeholder="Indicatif"
            v-model="gsmFormData.indicatif" />
        </div>
      </div>

      <div class="row form-group">
        <div class="col-md-6 form-group">
          <label>Nombre de chaînes</label>
          <input type="number" class="form-control" placeholder="Nombre de chaînes"
            v-model="gsmFormData.nbre_chaine" />
        </div>
        <div class="col-md-6 form-group">
          <label>Préfixes</label>
          <input type="text" class="form-control" placeholder="Préfixes (séparés par des virgules)"
            v-model="gsmFormData.prefixe" />
        </div>
      </div>

      <div class="row form-group">
        <div class="col-md-6 form-group">
          <label>Mode feda</label>
          <input type="text" class="form-control" placeholder="Mode feda"
            v-model="gsmFormData.mode_feda" />
        </div>
        
      </div>


      </form>

      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="addGsmModal = false">Fermer</b-button>
        <b-button variant="primary" @click="addReseauMethod">
          Ajouter
        </b-button>
      </template>
    </b-modal>


    <!-- <div class="my-5 d-flex align-items-center" style="font-size:larger; font-weight: bold;"> Les majorations
      <button class="btn btn-primary mx-3 mb-2" @click="prefixeAdd"> Ajouter </button>
    </div> -->

  </Layout>
</template>

<style>
.box {
  background-color: #3AAA35;
  color: white;
  border-radius: 5px;
  font-size: 1.5rem;
  padding: 20px;
  box-shadow: 5px 10px rgba(144, 238, 144, 0.417);
}

.box-tarif {
  font-weight: light;
  font-size: 1em;
}

.formule {
  font-weight: bold;
}

.detail-prix {
  font-size: 0.6em;
  font-weight: light;
}

thead {
  background: #3AAA35;
}


thead div {
  color: #fff;

}

.slide-enter-active,
.slide-leave-active {
  transition: opacity 0.5s, transform 0.5s;
}

.slide-enter,
.slide-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.lineField td:nth-child(5) {
  max-width: 250px !important;
  white-space: nowrap;
  overflow-x: scroll !important;
  overflow-y: auto !important;
}

.lineField td:nth-child(2) {
  max-width: 200px !important;
  white-space: nowrap;
  overflow: auto;
}


/* td{
  max-height: 100px !important;
  overflow-y:scroll !important ;
} */


/* td {
  max-width: 200px !important;
  white-space: nowrap; 
  overflow: auto;
} */
.bg-color-custom {
  background-color: #3AAA35 !important;
  color: white;

}

.seat-bg {
  background-color: #5bcd3e;
}

.day {
  background-color: rgb(231, 225, 225);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  /* Ajustez la taille selon vos besoins */
  height: 40px;
  border-radius: 50%;
  font-size: 14px;
}

.day:hover {

  color: #fff;
  background-color: rgb(104, 87, 131) !important;
}

.input {
  right: 13px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 35px;
  bottom: 0;
  flex: 60%;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.containerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
  width: 250px;
  height: 40px;
  position: absolute;
  top: 8%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.pac-container {
  z-index: 10000 !important;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}

.input input:focus {
  border: 3px solid #3aaa35;
}

.inputCol {
  position: relative;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}

.nav-tabs .nav-link {
  background: #dc143c;
  transition: all .5s;
}

.nav-tabs .nav-link a {
  color: #fff;
}

.text-red {
  color: #dc143c;
}

.nav-tabs .nav-link a {
  color: #fff !important;
}

.text-green {
  color: #3AAA35;
}

.nav-tabs .nav-link a {
  color: rgba(44, 56, 74, 0.95);
}

.nav-tabs-custom .nav-item .nav-link.active a {
  color: blue;
  font-weight: 600 !important;
  vertical-align: middle;
}

.nav-tabs-custom .nav-item .nav-link.active {
  font-weight: 600;
  background: #3AAA35;
  border-bottom: 1px solid blue;
}

.nav-tabs-custom .nav-item .nav-link::after {
  border-bottom: 1px solid #000000;
}

input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  height: 25px;
  width: 25px;
  margin: auto;
  background-color: #d5d5d5;

  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  box-shadow: -10px -10px 15px rgba(255, 255, 255, 0.5),
    10px 10px 15px rgba(70, 70, 70, 0.12);
}

input[type="checkbox"]:checked {
  background-color: #5bcd3e;
}

input[type="checkbox"]:checked:after {
  display: block;

}

input[type="checkbox"]:after {
  font-weight: 600;
  font-family: FontAwesome;
  content: "\f00c";
  width: inherit;
  height: inherit;

  font-size: 20px;
  color: white;
  display: none;
}


.seat-container {
  border: 2px solid black;
  width: 35px;
  height: 35px;
  justify-content: center;
  border-radius: 5px;
  margin-right: 5px;
  /* Add margin between seats for spacing */
}

.row.d-flex .d-inline {
  display: flex;
  flex-direction: row;
}
</style>
